import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes: [
        {path: '/login', name: 'login', component: () => import("../views/Login.vue")},
        {
            path: '/main', name: 'Main', component: () => import("../views/Main.vue"), children: [
                {path: 'orders', name: 'Orders', component: () => import("../views/order/List.vue")},
                {
                    path: 'orders/detail/:orderId', name: 'OrderDetail', component: () => import("../views/order/Detail.vue"),
                    props: (route) => ({orderId: route.params.orderId})
                },
                {
                    path: 'orders/edit/:orderId', name: 'OrderEdit', component: () => import("../views/order/OrderEdit.vue"),
                    props: (route) => ({orderId: route.params.orderId})
                },
                {path: 'goods', name: 'Goods', component: () => import("../views/goods/Goods.vue")},
                {
                    path: 'goods/statistic',
                    name: 'GoodsStatistic',
                    component: () => import("../views/goods/Statistic.vue"),
                    props: (route) => ({form: route.params.form})
                },
                {path: 'customer', name: 'Customer', component: () => import("../views/customer/Customers.vue"),
                    props: (route) => ({sellerId: route.params.sellerId})
                },
                {
                    path: 'customer/detail/:customerId',
                    name: 'CustomerDetail',
                    component: () => import("../views/customer/CustomerDetail.vue"),
                    props: (route) => ({customerId: route.params.customerId})
                },
                {
                    path: 'customer/detail',
                    name: 'CustomerDetail',
                    component: () => import("../views/customer/CustomerDetail.vue"),
                    props: (route) => ({mobile: route.query.mobile})
                },
                {
                    path: 'customer/newOrder',
                    name: 'CustomerNewOrder',
                    component: () => import("../views/customer/NewOrder.vue"),
                    props: (route) => ({form: route.params.form})
                },

                {
                    path: 'goods/AddGoods',
                    name: 'AddGoods',
                    component: () => import("../views/goods/AddGoods.vue"),
                    props: (route) => ({form: route.params.form})
                },

                {path: 'user', name: 'users', component: () => import("../views/user/User.vue")},
                {path: 'seller', name: 'Seller', component: () => import("../views/seller/Sellers.vue")},
                {path: "", redirect: {name: 'Goods'}}
            ]
        },
    ]
})
