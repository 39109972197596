import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './style/theme/index.css';
import qs from 'qs';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.use(ElementUI);

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.withCredentials = true;


Vue.prototype.$http = axios;
Vue.http = axios;


router.beforeEach((to, from, next) => {
    if(to.path=='/login'){
        next();
    }else if(to.path == '/'){
        next("/main/goods")
    }
    else {
        console.log("to",to.path);
        let _path=to.path;
        axios.get("/api/logined").then((rsp => {
            console.log("路由页面前,判断是否登录")
            if (rsp.data.status != 200) {
                next("/login")
            } else {
               next();
            }
        }));
    }
});

const { create, all } = require('mathjs')
const config = {
    number:'number',
    precision:10
}
const math = create(all,config)
Vue.prototype.$math=math;

let fen2yuan=(fen)=> {
    if((typeof fen )== "undefined"||(fen.toString().trim()=='')){
        return  '';
    }
    return  math.divide(math.number(fen||0),100).toFixed(2);
}
let yuan2fen=(yuan)=>{
    if((typeof yuan )== "undefined"||(yuan.toString().trim()=='')){
        return  '';
    }
    return  (math.multiply(math.number(yuan||0),100)).toFixed(0).toString();
}

Vue.mixin({
    computed:{
        isAdmin(){
            return  3==(localStorage.getItem("type")||"");
        },
        isHr(){
            return 2==(localStorage.getItem("type")||"");
        }
    },
    filters: {
        fen2yuan:fen2yuan,
        yuan2fen:yuan2fen,
        money(x){
            let a=x*1;
            if(isNaN(a)){
                return "";
            }
            return a.toFixed(2);
        }
    },
    methods:{
        fen2yuan:fen2yuan,
        yuan2fen:yuan2fen,
        goBack(){
            this.$router.go(-1);
        }
    }
})

router.beforeEach((to, from, next) => {
    if((to.path=='/login')||(to.path=='/')){
        next();
    }else{
        axios.get("/api/logined").then((rsp => {
            if (rsp.data.status != 200) {
                next("/")
            } else {
                next();
            }
        })).catch(()=>{
            next("/")
        })
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
