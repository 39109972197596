<template>
  <div id="App">
    <router-view/>
  </div>

</template>
<script>
export default {
  name: "App",
  mounted() {
    // this.$http.get("/api/logined").then((rsp => {
    //   console.log("首次进入,判断是否登录")
    //   if (rsp.data.status != 200) {
    //     this.$router.push("/login")
    //   } else {
    //     var currentPath = window.location.hash.slice(1); // 获取输入的路由
    //     if (this.$router.path !== currentPath) {
    //       console.log("动态跳转")
    //       this.$router.push(currentPath); // 动态跳转
    //     }else{
    //       this.$router.push("/main/goods")
    //     }
    //   }
    // }));
    window.addEventListener('hashchange', () => {
      var currentPath = window.location.hash.slice(1); // 获取输入的路由
      if (this.$router.path !== currentPath) {
        console.log("动态跳转")
        this.$router.push(currentPath); // 动态跳转
      }
    }, false);
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 12px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

html, body {
  margin: 0;
  height: 100%;
}

.title {
  font: 24px bold;
}

.funBtn {
  font-size: 20px;
}


</style>
